<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    await this.$axios.client.RedirectCallback();
    this.$axios.client.man
      .getUser()
      .then((user) => {
        if (user) {
          const role = user.profile.role;
          switch (role) {
            case "Member": {
              this.$axios
                .getCustomerIdByUsername({
                  username: user.profile.preferred_username,
                })
                .then((res) => {
                  if (!res.error) {
                    localStorage.setItem("customerId", res);
                    this.$router.push("/user/order");
                  } else {
                    this.$notify.error({
                      title: "提示",
                      message: res.error,
                      duration: 2000,
                    });
                  }
                });
              break;
            }
            case "Manufacturer": {
              this.$axios
                .getServiceIdByUsername({
                  username: user.profile.preferred_username,
                })
                .then((res) => {
                  if (!res.error) {
                    localStorage.setItem("serviceId", res);
                    this.$router.push("/servicer/printer");
                  } else {
                    this.$notify.error({
                      title: "提示",
                      message: res.error,
                      duration: 2000,
                    });
                  }
                });
              break;
            }
            case "SuperAdmin": {
              this.$router.push("/admin/user");
              // this.$axios
              //   .getServiceIdByUsername({
              //     username: user.profile.preferred_username,
              //   })
              //   .then((res) => {
              //     if (!res.error) {
              //       localStorage.setItem("serviceId", res);
              //       this.$router.push("/admin/user");
              //     } else {
              //       this.$notify.error({
              //         title: "提示",
              //         message: res.error,
              //         duration: 2000,
              //       });
              //     }
              //   });
              break;
            }
            default: {
              this.$router.push("/home");
              break;
            }
          }
        }
      })
      .catch(() => {
        this.$notify.error({
          title: "提示",
          message: "获取用户信息失败",
          duration: 2000,
        });
        this.$router.push("/home");
      });
    // this.$router.push()
  },
};
</script>